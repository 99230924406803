import { useConfig, useSiteMetadata } from 'src/hooks';
import React from 'react';

interface Props {
  children?: any;
  pageTitle?: string;
}

export default ({ children, pageTitle }: Props) => {
  const { description, title } = useSiteMetadata();
  const { favicon, favicon32, touchIcon60, touchIcon76, touchIcon120, touchIcon152 } = useConfig();
  const userLocation = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <title>{pageTitle ? `${title} | ${pageTitle}` : title}</title>
      <meta name='description' content={description} />
      <link rel='shortcut icon' href={favicon} />
      <link rel='icon' sizes='16x16' href={favicon32} />
      <link rel='icon' sizes='32x32' href={favicon32} />
      <link rel='icon' sizes='60x60' href={touchIcon60} />
      <link rel='icon' sizes='76x76' href={touchIcon76} />
      <link rel='icon' sizes='120x120' href={touchIcon120} />
      <link rel='icon' sizes='152x152' href={touchIcon152} />
      <link rel='apple-touch-icon' sizes='76x76' href={touchIcon76} />
      <link rel='apple-touch-icon' sizes='120x120' href={touchIcon120} />
      <link rel='apple-touch-icon' sizes='152x152' href={touchIcon152} />
      <link rel='canonical' href={userLocation} />
      {children}
    </>
  );
};